
import {computed, defineComponent, ref} from "vue";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent, IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  loadingController,
} from "@ionic/vue";
import {useStore} from "vuex";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";
import {CreateExitDelivery, UpdateExitDelivery} from "@/domain/command/delivery/delivery.command";
import router from "@/app/router";
import {useViewStateApi} from "@/domain/service/api/viewState.api";
import CheckCreateExitDeliveryItemComponent from "@/app/view/delivery/check/CheckCreateExitDeliveryItemComponent.vue";


export default defineComponent({
  name: "CheckCreateExitDeliveryPage",
  components: {
    CheckCreateExitDeliveryItemComponent,
    IonTitle,
    IonHeader,
    IonButton,
    IonBackButton,
    IonPage,
    IonCard,
    IonCardContent,
    IonRow,
    IonContent,
    IonToolbar,
    IonButtons,
    IonGrid,

  },
  setup() {
    const store = useStore();
    const exitDeliveryForCheck = computed<UpdateExitDelivery>(() => {
      return store.getters.getCheckUpdateExitDeliveryState
    });
    const {updateExitDelivery} = useDeliveryApi();
    const {resetCheckUpdateExitDeliveryState} = useViewStateApi();

    const isSubmitting = ref(false);


    const submit = async () => {
      if (isSubmitting.value)
        return;


      const loading = await loadingController
          .create({
            message: 'Guardando albarán de salida',
            duration: 5000,
          });

      await loading.present();
      const newCommand: UpdateExitDelivery = {...exitDeliveryForCheck.value};
      console.log(newCommand);
      await updateExitDelivery(newCommand);
      await useDeliveryApi().fetchNextExitIndex();
      resetCheckUpdateExitDeliveryState();

      await loading.dismiss();
      await router.push({path: `/app/albaran-salida/historial`})
    };

    return {exitDeliveryForCheck, submit};
  }
})
